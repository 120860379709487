import flatten from 'lodash/flatten';
import omit from 'lodash/omit';

import {isPickupStop} from 'core/entities/Load/modules/loadStop';
import Load, {LoadStop, PickupStop} from 'core/entities/Load/types';

import {EmailsToUpdate} from 'pages/Loads/components/common/Forms/GeneralInfo/hooks/useEmailsToUpdate';

import {convertDate} from 'utils/dateTime';
import {convertFromLbs, convertToLbs, transformWeightToNumber} from 'utils/weightConverter';

import {NewNote} from 'types/NewNote';

import {LoadStopParamsForApi} from '../types';

type StopData = LoadStop;
type StopFreights = PickupStop['freights'];

export const convertFreightDataFromLbs = (freights: StopFreights): StopFreights => {
    return freights.map((freight) => {
        return {
            ...freight,
            pieces: Number(freight.pieces),
            weight: {
                ...freight.weight,
                amount: transformWeightToNumber(convertFromLbs(freight.weight.unit, freight.weight?.amount)),
            },
        };
    });
};

export const convertFreightDataToLbs = (freights: StopFreights): StopFreights => {
    return freights.map((freight) => {
        return {
            ...freight,
            pieces: Number(freight.pieces),
            weight: {
                ...freight.weight,
                amount: transformWeightToNumber(convertToLbs(freight.weight.unit, freight.weight?.amount)),
            },
        };
    });
};

export const convertToStopDataForApi = (stopData: StopData, pickupDeliveryIndex: number): LoadStopParamsForApi => {
    const convertedStop = {
        ...stopData,
        dateStart: convertDate(stopData.dateStart, {timeZoneFrom: stopData.timeZone, timeZoneTo: 'utc'})
            .defaultDateTime,
        dateEnd: convertDate(stopData.dateEnd, {timeZoneFrom: stopData.timeZone, timeZoneTo: 'utc'}).defaultDateTime,
        orderNumber: pickupDeliveryIndex + 1,
        facility: null,
        facilityId: stopData?.facility ? (stopData?.facility.id as number) : null,
    };
    if (isPickupStop(convertedStop)) {
        convertedStop.freights = convertFreightDataToLbs(convertedStop.freights);
    }

    if (stopData.dateStart === null) {
        return omit(convertedStop, ['dateStart']);
    }

    return convertedStop;
};

export const convertToStopDataForUpdate = (stopData: LoadStop): LoadStop => {
    const convertedStop = {
        ...stopData,
        commodity: stopData.commodity || '',
        dateStart: convertDate(stopData.dateStart, {timeZoneFrom: 'utc', timeZoneTo: stopData.timeZone})
            .defaultDateTime,
        dateEnd: convertDate(stopData.dateEnd, {timeZoneFrom: 'utc', timeZoneTo: stopData.timeZone}).defaultDateTime,
    };
    if (isPickupStop(convertedStop)) {
        convertedStop.freights = convertFreightDataFromLbs(convertedStop.freights);
    }

    return convertedStop;
};

export const loadDataCanBeUpdated = (load: Load): boolean => load.canUpdate;

export const checkOnlyEnabledStops = (stop: LoadStopParamsForApi): boolean => {
    return stop.status !== 'in progress' && stop.status !== 'completed';
};

export const transformGeneralNoteToApi = (note: string | null): Pick<NewNote, 'note' | 'isImportant'> | undefined => {
    if (!note) {
        return;
    }

    return {
        note,
        isImportant: false,
    };
};

export const transformEmailsFromApi = (load: Load): EmailsToUpdate => {
    const dispatcherEmail = load.bookedByDispatcher?.email || '';
    const agentEmail = load.agent?.email || '';
    const salesRepEmail = load?.bookedBySalesRep?.email || '';

    return {
        salesRep: salesRepEmail,
        dispatcher: dispatcherEmail,
        agent: agentEmail,
        additional: load.emailsToUpdate.filter(
            (email) => email !== dispatcherEmail && email !== agentEmail && email !== salesRepEmail,
        ),
    };
};

export const transformEmailsToApiRequest = (loadFields): string[] => {
    const omittedFields = [
        !loadFields.dispatcher_send_updates ? 'dispatcher' : '',
        !loadFields.salesRep_send_updates ? 'salesRep' : '',
        !loadFields.agent_send_updates ? 'agent' : '',
    ];

    return flatten(Object.values(omit(loadFields.emailsToUpdate, omittedFields))).filter(Boolean);
};
