import {matchPath} from 'react-router-dom';

import paths from '../paths';
import {
    CarriersRouteParams,
    CustomersRouteParams,
    DispatchersRouteParams,
    FacilitiesRouteParams,
    FactoringCompaniesRouteParams,
    LoadsRouteParams,
    TripMonitorRouteParams,
    TrucksRouteParams,
} from '../types';

const getParams = <T extends {[K: string]: string}>(routePath: string) => {
    return (pathname: string): Partial<T> => {
        const match = matchPath<T>(pathname, {
            path: routePath,
        });

        return match?.params ?? {};
    };
};

export const getTrucksRouteParams = getParams<TrucksRouteParams>(`${paths.trucks}/:action?/:id?`);

export const getDispatchersRouteParams = getParams<DispatchersRouteParams>(`${paths.dispatchers}/:action?/:id?`);

export const getCarriersRouteParams = getParams<CarriersRouteParams>(`${paths.carriers}/:action?/:id?`);

export const getLoadsRouteParams = getParams<LoadsRouteParams>(`${paths.loads}/:action?/:id?`);

export const getFacilitiesRouteParams = getParams<FacilitiesRouteParams>(`${paths.facilities}/:action?/:id?`);

export const getCustomersRouteParams = getParams<CustomersRouteParams>(`${paths.customers}/:action?/:id?`);

export const getFactoringCompaniesRouteParams = getParams<FactoringCompaniesRouteParams>(
    `${paths['factoring-companies']}/:action?/:id?`,
);

export const getTripMonitorRouteParams = getParams<TripMonitorRouteParams>(
    `${paths['trip-monitor']}/:action?/:id?/:tabName?`,
);
