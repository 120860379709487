import includes from 'lodash/includes';
import isFunction from 'lodash/isFunction';
import isNumber from 'lodash/isNumber';
import {change, initialize, reset} from 'redux-form';

import * as appActions from 'store/actions';
import {getCurrentRegion} from 'store/reducers/appSettings/selectors';

import Truck from 'core/entities/Truck/types';
import {DispatcherNoteApi} from 'core/entities/Truck/types/Note';
import * as requests from 'core/gateways/FullAllianceApiGateway/requests';

import {awaySortValues, SEARCH_FORM_NAME, SortBy} from 'pages/FullAlliance/constants';
import * as types from 'pages/FullAlliance/redux/actionTypes';
import {transformSearchDataToRequestBody} from 'pages/FullAlliance/redux/mappers/searchForm';
import * as selectors from 'pages/FullAlliance/redux/selectors';
import {FullAllianceSearchFormValues} from 'pages/FullAlliance/types/formTypes';
import {createInitFormValues} from 'pages/FullAlliance/utils';

import transformApiPaginationToClient from 'utils/pagination';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import {PlaceAddress} from 'types/Address';
import Pagination from 'types/Pagination';

const getName = getTypeFieldNameFactory<FullAllianceSearchFormValues>();

export const listActionCreators = {
    updatePartnerTruck: (payload: {truck: Truck}) => ({type: types.PARTNER_TRUCK_UPDATED, payload} as const),
    setPagination: (payload: {pagination: Pagination}) => ({type: types.PAGINATION_RECEIVED, payload} as const),
    setSortBy: (payload: {sortBy: SortBy}) => ({type: types.LIST_SORT_BY_RECEIVED, payload} as const),
    setExpandedIDs: (payload: {expandedIDs: number[]}) => ({type: types.EXPANDED_IDS_RECEIVED, payload} as const),
    setTrucks: (payload: {items: Truck[]}) => ({type: types.TRUCKS_RECEIVED, payload} as const),
    setLocationPoints: (payload: {origin: PlaceAddress | null; destination: PlaceAddress | null}) =>
        ({type: types.LOCATION_POINTS_RECEIVED, payload} as const),
    setSearchParams: (payload: {searchParams: Partial<FullAllianceSearchFormValues>}) =>
        ({type: types.SEARCH_PARAMS_RECEIVED, payload} as const),
    clearListState: () => ({type: types.LIST_STATE_CLEARED} as const),
};

const getTrucks = (params: {requestBody}) => async (dispatch) => {
    const {requestBody} = params;

    try {
        dispatch(appActions.showLoader());

        const {data} = await requests.getFullAllianceTrucks({requestPayload: requestBody});

        return {...data, pagination: transformApiPaginationToClient(data.pagination)};
    } catch (error) {
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

const setLocationPoints = (params: {searchResult}) => (dispatch) => {
    const {searchResult} = params;

    const points = {origin: searchResult?.origin_location, destination: searchResult?.destination_location};

    dispatch(listActionCreators.setLocationPoints(points));
};

const updateLocationPointsFormFields = () => (dispatch, getState) => {
    const state = getState();

    const searchParams = selectors.getSearchParams(state);

    if (searchParams?.originPoint) {
        dispatch(change(SEARCH_FORM_NAME, getName('originPoint'), searchParams?.originPoint));
    }

    if (searchParams?.destinationPoint) {
        dispatch(change(SEARCH_FORM_NAME, getName('destinationPoint'), searchParams?.destinationPoint));
    }
};

export const getTrucksByFilters = (params: {
    filters: Partial<FullAllianceSearchFormValues>;
    onPostSuccessFunc?: () => void;
}) => async (dispatch, getState) => {
    const {filters: followingFilters, onPostSuccessFunc} = params;

    const state = getState();

    const pagination = selectors.getPagination(state);
    const sorting = selectors.getSorting(state);
    const sortBy = followingFilters.destinationPoint ? sorting.sortBy : awaySortValues.origin;

    const transformedSearchData = transformSearchDataToRequestBody({formData: followingFilters});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        tnbSortBy: sortBy,
        page: 1,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return;
    }

    if (isFunction(onPostSuccessFunc)) {
        onPostSuccessFunc();
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setSortBy({sortBy}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setSearchParams({searchParams: followingFilters}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(updateLocationPointsFormFields());
};

export const getTrucksByPage = (params: {page: number}) => async (dispatch, getState) => {
    const {page: followingPage} = params;

    const state = getState();

    const searchParams = selectors.getSearchParams(state);
    const pagination = selectors.getPagination(state);
    const sorting = selectors.getSorting(state);

    const transformedSearchData = transformSearchDataToRequestBody({formData: searchParams});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        tnbSortBy: sorting.sortBy,
        page: followingPage,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(updateLocationPointsFormFields());
};

export const getTrucksByPerPage = (params: {perPage: number}) => async (dispatch, getState) => {
    const {perPage: followingPerPage} = params;

    const state = getState();

    const searchParams = selectors.getSearchParams(state);
    const sorting = selectors.getSorting(state);

    const transformedSearchData = transformSearchDataToRequestBody({formData: searchParams});

    const requestBody = {
        ...transformedSearchData,
        tnbSortBy: sorting.sortBy,
        perPage: followingPerPage,
        page: 1,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(updateLocationPointsFormFields());
};

export const getTrucksBySortBy = (params: {sortBy: SortBy}) => async (dispatch, getState) => {
    const {sortBy: followingSortBy} = params;

    const state = getState();

    const searchParams = selectors.getSearchParams(state);
    const pagination = selectors.getPagination(state);

    const transformedSearchData = transformSearchDataToRequestBody({formData: searchParams});

    const requestBody = {
        ...transformedSearchData,
        perPage: pagination.perPage,
        tnbSortBy: followingSortBy,
        page: 1,
    };

    const searchResult = await dispatch(getTrucks({requestBody}));

    if (!searchResult) {
        return null;
    }

    dispatch(setLocationPoints({searchResult}));
    dispatch(listActionCreators.setTrucks({items: searchResult.items}));
    dispatch(listActionCreators.setPagination({pagination: searchResult.pagination}));
    dispatch(listActionCreators.setSortBy({sortBy: followingSortBy}));
    dispatch(updateLocationPointsFormFields());
};

export const toggleTruckRow = (params: {truckID: number}) => (dispatch, getState) => {
    const {truckID} = params;

    const state = getState();

    if (!truckID || !isNumber(truckID)) {
        return null;
    }

    const expandedIDs = selectors.getExpandedIDs(state);

    const isFollowingRowExpanded = includes(expandedIDs, truckID);

    const expandedIDsWithNewID = [...expandedIDs, truckID];
    const expandedIDsWithOutFollowingID = expandedIDs.filter((id) => id !== truckID);

    const newExpandedIDs = isFollowingRowExpanded ? expandedIDsWithOutFollowingID : expandedIDsWithNewID;

    dispatch(listActionCreators.setExpandedIDs({expandedIDs: newExpandedIDs}));
};

export const toggleAllTruckRows = () => (dispatch, getState) => {
    const state = getState();

    const expandedIDs = selectors.getExpandedIDs(state);
    const trucks = selectors.getTrucks(state);

    const newExpandedIDs = !expandedIDs.length ? trucks.map((truck) => truck.id) : [];

    dispatch(listActionCreators.setExpandedIDs({expandedIDs: newExpandedIDs}));
};

export const resetSearchForm = () => (dispatch, getState) => {
    const state = getState();
    const currentRegion = getCurrentRegion(state);

    const initFormValues = createInitFormValues(currentRegion);

    dispatch(reset(SEARCH_FORM_NAME));

    dispatch(initialize(SEARCH_FORM_NAME, initFormValues));
};

export const receiveDispatcherNote = (params: {truck: Truck; note?: DispatcherNoteApi}) => (dispatch) => {
    dispatch({type: types.DISPATCHER_NOTE_UPDATE, payload: params});
};

export const updatePartnerTruck = (params: {truck: Truck}) => (dispatch) => {
    dispatch(listActionCreators.updatePartnerTruck(params));
};
