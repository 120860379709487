import axios, {AxiosResponse, CancelTokenSource} from 'axios';
import intersection from 'lodash/intersection';
import isEmpty from 'lodash/isEmpty';
import isNull from 'lodash/isNull';
import mapValues from 'lodash/mapValues';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import pick from 'lodash/pick';

import {AppState, AppThunk} from 'store';
import * as appActions from 'store/actions';
import adminActions from 'store/reducers/adminSettings/actionCreators';
import {getShowDeleted} from 'store/reducers/adminSettings/selectors';
import {getCurrentSortBy, getCurrentSortFrom} from 'store/reducers/sort/selectors';

import Truck from 'core/entities/Truck/types';
import * as truckRequests from 'core/gateways/TruckApiGateway/requests';

import {getNewCancelTokenSource} from 'services/restapi';

import * as modalActions from 'components/ui/ModalProvider/actions';

import {characteristics as fields} from 'pages/Trucks/constants/fields';

import * as entityNames from 'utils/data/entityNames';
import {getTemperatureUnitByLabel} from 'utils/data/temperatureUnits';
import formatPaginationParams from 'utils/formatPaginationParams';
import {convertToInches} from 'utils/lengthConverter';
import parsePaginationHeaders from 'utils/parsePaginationHeaders';
import {convertToLbs} from 'utils/weightConverter';

import * as types from '../actionTypes';
import {transformFormDataToRequestBody} from '../mappers/status';
import {UpdateStatusModalFormValues} from '../types/formTypes';

const formatSearchParams = (params) =>
    omitBy(
        {
            ...params,
            license_country: params.license_country && params.license_country.value,
            license_state: params.license_state && params.license_state.value,
            reserved_by: params.reserved_by && params.reserved_by.value,
            driver: params.driver && params.driver.value,
            owner: params.owner && params.owner.value,
            year: params.year && params.year.value,
            temperatureDegreesUnit:
                params.temperatureRangeMin || params.temperatureRangeMax
                    ? getTemperatureUnitByLabel(params.temperatureDegreesUnit)
                    : null,
            temperatureRangeMin: Number(params.temperatureRangeMin) || null,
            temperatureRangeMax: Number(params.temperatureRangeMax) || null,
        },
        isNull,
    );

export function toggleDrawerExpand(toggleData) {
    return {
        type: types.TOGGLE_DRAWER_EXPAND,
        payload: {toggleData},
    };
}

const getTrucksDecorator = () => {
    let axiosSourceForFetchingTrucks: CancelTokenSource | null = null;

    return (state: AppState) => {
        try {
            const {searchParams, pagination} = state.trucks.list;
            const currentSortFrom = getCurrentSortFrom(state, 'trucks');
            const currentSortBy = getCurrentSortBy(state, 'trucks');
            const params = {
                sortBy: currentSortBy ? {[currentSortBy]: currentSortFrom} : {},
                ...formatPaginationParams(pagination),
                is_deleted: getShowDeleted(state, entityNames.ENTITY_NAME_TRUCKS),
            };

            if (!isEmpty(searchParams)) {
                if (axiosSourceForFetchingTrucks) {
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    // @ts-ignore
                    axiosSourceForFetchingTrucks.cancel('fetching trucks was canceled by the user.');
                    axiosSourceForFetchingTrucks = null;
                }

                // calculations for dimension units
                const dimsFieldsNames = [fields.valid_dims_height, fields.valid_dims_length, fields.valid_dims_width];
                const dimsObject = pick(formatSearchParams(searchParams), dimsFieldsNames);
                const dimsUnit = pick(formatSearchParams(searchParams), fields.dims_units)[fields.dims_units];
                const transformedDims = mapValues(dimsObject, (item) => convertToInches(dimsUnit, item));

                // calculations for payload units
                const payloadFieldsNames = [fields.payload, fields.gross_weight];
                const payloadObject = pick(formatSearchParams(searchParams), payloadFieldsNames);
                const payloadUnit = pick(formatSearchParams(searchParams), fields.payload_units)[fields.payload_units];
                const transformedPayload = mapValues(payloadObject, (item) => convertToLbs(payloadUnit, item));
                const commonEquipmentList = searchParams?.equipment?.split(',') || [];
                const basicEquipmentList = state.registry.truckBasicEquipment.map((elem) => elem.value);
                const additionalEquipmentList = state.registry.truckAdditionalEquipment.map((elem) => elem.value);
                const basicEquipmentFields = intersection(commonEquipmentList, basicEquipmentList);
                const additionalEquipmentFields = intersection(commonEquipmentList, additionalEquipmentList);

                // Truck and Driver Expiration
                const [truckExpDateFrom, truckExpDateTo] =
                    searchParams.truckExpiration?.dateRange?.split?.(' - ') || [];
                const [driverExpDateFrom, driverExpDateTo] =
                    searchParams.driverExpiration?.dateRange?.split?.(' - ') || [];
                const truckExpDateRange =
                    truckExpDateFrom && truckExpDateTo ? {from: truckExpDateFrom, to: truckExpDateTo} : null;
                const driverExpDateRange =
                    driverExpDateFrom && driverExpDateTo ? {from: driverExpDateFrom, to: driverExpDateTo} : null;
                const truckExpiration = searchParams.truckExpiration
                    ? {...searchParams.truckExpiration, dateRange: truckExpDateRange}
                    : null;
                const driverExpiration = searchParams.driverExpiration
                    ? {...searchParams.driverExpiration, dateRange: driverExpDateRange}
                    : null;

                const searchParamsWithTransformedMeasurements = {
                    ...formatSearchParams(searchParams),
                    ...transformedDims,
                    ...transformedPayload,
                    truckExpiration,
                    driverExpiration,
                    equipment: basicEquipmentFields.join(','),
                    additional_equipment: additionalEquipmentFields.join(','),
                };

                const searchParamsWithOmittedUnitsOfMeasurements = omit(searchParamsWithTransformedMeasurements, [
                    fields.payload_units,
                    fields.dims_units,
                ]);

                return truckRequests.searchTrucks({...params, ...searchParamsWithOmittedUnitsOfMeasurements});
            }

            axiosSourceForFetchingTrucks = getNewCancelTokenSource();

            const {token: cancelToken} = axiosSourceForFetchingTrucks;

            return truckRequests.getTrucks(params, cancelToken);
        } catch (error) {
            console.error(error);
            throw new Error(error as any);
        }
    };
};

const getTrucks = getTrucksDecorator();

export function receiveDispatcherNote(payload) {
    return {
        type: types.TRUCK_DISPATCHER_NOTE_UPDATED,
        payload,
    };
}

export const receiveTrucks = (response: AxiosResponse<Truck[]>) => ({
    type: types.RECEIVE_TRUCKS,
    payload: {
        trucks: response.data,
        pagination: parsePaginationHeaders(response.headers),
    },
});

export const fetchTrucks = () => async (dispatch, getState) => {
    try {
        dispatch(appActions.startLoadingData());

        const response = await getTrucks(getState());

        dispatch(receiveTrucks(response));
        dispatch(appActions.endLoadingData());
    } catch (err) {
        if (!axios.isCancel(err)) {
            dispatch(appActions.handleError(err));
            dispatch(appActions.endLoadingData());
        }
    }
};

export function viewNotes(selectedTruck): AppThunk {
    return function (dispatch) {
        dispatch(appActions.showLoader());
        dispatch(
            toggleDrawerExpand({
                drawerExpand: true,
                truck: selectedTruck,
            }),
        );
        dispatch(appActions.hideLoader());
    };
}

// export for unit tests
export function receiveStatusUpdate(payload) {
    return {
        type: types.TRUCK_STATUS_UPDATE,
        payload,
    };
}

export const updateStatus = (params: {truck: Truck; formData: UpdateStatusModalFormValues}): AppThunk => async (
    dispatch,
) => {
    const {truck, formData} = params;

    const requestBody = transformFormDataToRequestBody({formData});

    try {
        dispatch(appActions.showLoader());

        await truckRequests.updateTruckStatus({truckID: truck.id, requestBody});

        const {data} = await truckRequests.getTruckByNumber(truck.number);

        dispatch(receiveStatusUpdate(data));
        dispatch(modalActions.closeAll());
    } catch (error) {
        dispatch(modalActions.closeAll());
        dispatch(appActions.handleError(error));
    } finally {
        dispatch(appActions.hideLoader());
    }
};

export function setSearchParams(searchParams = {}) {
    return {
        type: types.TRUCKS_SET_SEARCH_PARAMS,
        payload: {
            searchParams,
        },
    };
}

export function setCurrentPage(page) {
    return {
        type: types.TRUCKS_SET_CURRENT_PAGE,
        payload: page,
    };
}

export function setPagination(currentPage, perPage) {
    return {
        type: types.TRUCKS_SET_PAGINATION,
        payload: {currentPage, perPage},
    };
}

export function toggleTruckRow(truckID) {
    return {
        type: types.TRUCK_TOGGLE_ROW,
        payload: {
            truckID,
        },
    };
}

export function toggleAllTruckRows(rowsIds) {
    return {
        type: types.TRUCK_TOGGLE_ALL_ROWS,
        payload: {
            rowsIds,
        },
    };
}

export function setShowArchivedTrucks({showArchived}: {showArchived: boolean}) {
    return function (dispatch) {
        dispatch(setCurrentPage(1));
        dispatch(adminActions.setAdminSettings({trucks: {showDeleted: showArchived}}));
    };
}

export function clearState() {
    return {
        type: types.CLEAR_STATE,
    };
}
