import React, {useCallback, useEffect, useRef, useState} from 'react';
import ReactAutoComplete from 'react-autocomplete';
import classNames from 'classnames';
import debounce from 'lodash/debounce';

import appConfig from 'config';

import {getPlaces} from 'services/mapsApi/expediteApi';

import TextInput from 'components/ui/Form/TextInput';

import {NOT_FOUND_LOCATION} from './constants';
import {AddressData, ExtendedPlaceItem} from './types';
import {extendPlacesItemsData, liftUpOnlyUSACountry} from './utils';

import styles from './styles.module.scss';

// temp will AddressData be removed after next refactoring
type Place = AddressData;

type OwnProps = {
    input: {
        onChange(string): void;
        onBlur();
        value: string;
    };
    meta: any;
    placeholder: string | undefined;
    label: string | null;
    disabled: boolean;
    withSubmitBtn?: boolean;
    isStrictZip: boolean;
    clearable?: boolean;
    autocomplete?: string;
    // deprecated
    onGetAddress(address: AddressData);
    onSelectPlace(address: AddressData);
    searchByCity: boolean | undefined;
    withUnknownValues?: boolean;
    // for tests
    isOpen: boolean | undefined;
    maxLength?: number;
};

const debouncedRequest = (cb: any, isStrictZip: boolean): any => {
    const DEBOUNCE_MS = 200;
    return debounce(async (value) => {
        const places = await getPlaces(value, isStrictZip);

        if (!places?.length) {
            cb([{fullAddress: NOT_FOUND_LOCATION}]);
            return;
        }

        const extendedPlacesItems = extendPlacesItemsData(liftUpOnlyUSACountry(places));

        if (extendedPlacesItems) {
            cb(extendedPlacesItems);
        }
    }, DEBOUNCE_MS);
};

const InputWithPlacesAutocomplete: React.FC<OwnProps> = (props) => {
    const [placeItems, setPlaceItems] = useState<ExtendedPlaceItem[]>([]);
    const inputRef = useRef<HTMLInputElement>(null);
    const getPlacePredictions = useCallback(debouncedRequest(setPlaceItems, props.isStrictZip), []);

    const onChangeHandler = (event: any): void => {
        event.persist();
        const {
            input: {onChange},
        } = props;
        const {value} = event.target;

        if (!value.trim()) {
            onChange('');
            return;
        }
        onChange(value);
        getPlacePredictions(value);
    };

    const onSelectHandler = (value, placeItem: ExtendedPlaceItem): void => {
        const getOnChangeValueFromPlace = (place: ExtendedPlaceItem): string => {
            if (props.searchByCity) {
                return place.address.city;
            }
            if (props.withUnknownValues) {
                return place.fullAddressWithUnknownValues;
            }
            return place.fullAddress;
        };

        if (!placeItem || placeItem.fullAddress === NOT_FOUND_LOCATION) {
            return;
        }

        const currentValue = getOnChangeValueFromPlace(placeItem);

        props.input.onChange(currentValue);
        props.onGetAddress?.({coords: placeItem.coords, address: placeItem.address, cityLine: currentValue});
        props.onSelectPlace?.({coords: placeItem.coords, address: placeItem.address, cityLine: currentValue});
    };

    const getItemValue = (): string => {
        // because 'react-autocomplete' matches values ONLY at the beginning of string https://github.com/reactjs/react-autocomplete/issues/239
        // we should match values manual
        const {input} = props;

        const matchedPlace = placeItems.find((place: ExtendedPlaceItem) =>
            place.fullAddress.toLowerCase().includes(input.value.toLocaleLowerCase()),
        );

        if (matchedPlace) {
            return input.value;
        }

        return '';
    };

    const renderItem = (placeItem: ExtendedPlaceItem, highlighted): JSX.Element => {
        if (placeItem.fullAddress === NOT_FOUND_LOCATION) {
            return (
                <div className="d-flex">
                    <i className="fa fa-exclamation-circle" />
                    <div className="ml10" />
                    {placeItem.fullAddress}
                </div>
            );
        }

        return (
            <div
                key={placeItem.id}
                className={highlighted ? `${styles.result_item} ${styles.active}` : styles.result_item}
            >
                {placeItem.fullAddress}
            </div>
        );
    };

    const renderMenu = (items): JSX.Element => {
        if (items.length) {
            return (
                <div
                    className={classNames(styles.result_box, {
                        [styles.warning]: items[0]?.props?.children[2] === NOT_FOUND_LOCATION,
                    })}
                >
                    <div data-testid="places">{items}</div>
                </div>
            );
        }

        return <div />;
    };

    const {
        input: {value},
        meta,
        disabled,
        label = null,
        maxLength,
    } = props;

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.focus();
        }
    }, []);

    const customProps = (props.isOpen || appConfig.isTestMode) && {
        open: true,
    };

    const valueWithMaxLength = value.slice(0, maxLength);

    return (
        <ReactAutoComplete
            {...customProps}
            wrapperStyle={{}}
            wrapperProps={{
                className: styles.google_places_autocomplete,
            }}
            ref={inputRef}
            items={placeItems}
            getItemValue={getItemValue}
            renderItem={renderItem}
            renderMenu={renderMenu}
            inputProps={{
                input: props.input,
                onBlur: props.input.onBlur,
                placeholder: props.placeholder,
                meta,
                label,
                disabled,
                errorTooltipPlacement: 'bottom',
                withSubmitBtn: props.withSubmitBtn,
                clearable: props.clearable,
                autocomplete: props.autocomplete || 'off',
                keyDownWithoutCheckForRepeat: true,
            }}
            renderInput={TextInput}
            value={valueWithMaxLength}
            onChange={onChangeHandler}
            onSelect={onSelectHandler}
            menuStyle={{
                borderRadius: '3px',
                boxShadow: '0 2px 12px rgba(0, 0, 0, 0.1)',
                position: 'inherit',
                overflow: 'auto',
                maxHeight: '50%',
                zIndex: 10000,
            }}
        />
    );
};

// deprecated
export type {AddressData};
export type {Place};

// for tests
export {InputWithPlacesAutocomplete};

export default InputWithPlacesAutocomplete;
