import React, {Suspense} from 'react';
import {Redirect, Switch} from 'react-router-dom';

import Spinner from 'components/ui/AppLoader/Spinner';

import {PERMISSIONS} from '../core/entities/Permissions';
import useAuction from '../hooks/useAuction';

import AppRoute from './components/AppRoute';

import paths from './paths';

const FactoringCompanies = React.lazy(() => import('../pages/FactoringCompanies'));
const TrucksNearby = React.lazy(() => import('../pages/TrucksNearby'));
const ReleaseNotes = React.lazy(() => import('../pages/ReleaseNotes'));
const FullAlliance = React.lazy(() => import('../pages/FullAlliance'));
const TravelOrders = React.lazy(() => import('../pages/TravelOrders'));
const Login = React.lazy(() => import('../pages/Login/Dispatchland'));
const Settlements = React.lazy(() => import('../pages/Settlements'));
const Dispatchers = React.lazy(() => import('../pages/Dispatchers'));
const Facilities = React.lazy(() => import('../pages/Facilities'));
const Customers = React.lazy(() => import('../pages/Customers'));
const TrucksMap = React.lazy(() => import('../pages/TrucksMap'));
const LoadBoard = React.lazy(() => import('../pages/LoadBoard'));
const MyCompany = React.lazy(() => import('../pages/MyCompany'));
const Invoices = React.lazy(() => import('../pages/Invoices'));
const Carriers = React.lazy(() => import('../pages/Carriers'));
const NotFound = React.lazy(() => import('../pages/NotFound'));
const Drivers = React.lazy(() => import('../pages/Drivers'));
const Reports = React.lazy(() => import('../pages/Reports'));
const Trucks = React.lazy(() => import('../pages/Trucks'));
const Owners = React.lazy(() => import('../pages/Owners'));
const Loads = React.lazy(() => import('../pages/Loads'));
const Logs = React.lazy(() => import('../pages/Logs'));

const Routing: React.FC = () => {
    const isAuctionEnabled = useAuction();

    return (
        <Suspense fallback={<Spinner />}>
            <Switch>
                <AppRoute type="private" exact permission={PERMISSIONS.MENU_LOADS_VIEW} path="/" component={Loads} />

                <AppRoute type="anonymous" path={paths.login} component={Login} />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_MAP_VIEW}
                    path={paths.map}
                    component={TrucksMap}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_TRUCK_NEARBY_VIEW}
                    path={paths['trucks-nearby']}
                    component={TrucksNearby}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_TRUCK_LIST_VIEW}
                    path={paths.trucks}
                    component={Trucks}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_REPORTS_VIEW}
                    path={paths.reports}
                    component={Reports}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_LOADS_VIEW}
                    path={paths.loads}
                    component={Loads}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_INVOICES_VIEW}
                    path={paths.invoices}
                    component={Invoices}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_OWNER_SETTLEMENTS_VIEW}
                    path={paths.settlements}
                    component={Settlements}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_CARRIER_SETTLEMENTS_VIEW}
                    path={paths.settlements}
                    component={Settlements}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_TRIP_MONITOR_VIEW}
                    path={paths['trip-monitor']}
                    component={TravelOrders}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_OWNERS_VIEW}
                    path={paths.owners}
                    component={Owners}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_DRIVERS_VIEW}
                    path={paths.drivers}
                    component={Drivers}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_CARRIERS_VIEW}
                    path={paths.carriers}
                    component={Carriers}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_FACILITIES_VIEW}
                    path={paths.facilities}
                    component={Facilities}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_FACTORING_COMPANIES_VIEW}
                    path={paths['factoring-companies']}
                    component={FactoringCompanies}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_CHANGES_LOG_VIEW}
                    path={paths['changes-log']}
                    component={Logs}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_USERS_VIEW}
                    path={paths.dispatchers}
                    component={Dispatchers}
                />

                <AppRoute
                    type="private"
                    permission={PERMISSIONS.MENU_CUSTOMERS_VIEW}
                    path={paths.customers}
                    component={Customers}
                />

                <AppRoute
                    type="private"
                    exact
                    permission={PERMISSIONS.MENU_RELEASE_NOTES_VIEW}
                    path={paths['release-notes']}
                    component={ReleaseNotes}
                />

                <AppRoute
                    permission={PERMISSIONS.MENU_MY_COMPANY_VIEW}
                    path={paths['my-company']}
                    component={MyCompany}
                    type="private"
                    exact
                />

                <AppRoute
                    permission={PERMISSIONS.MENU_TRUCK_FULL_ALLIANCE_VIEW}
                    path={paths['full-alliance']}
                    component={FullAlliance}
                    type="private"
                    exact
                />

                {/* // TODO: temporary, logic need to be moved to backend */}
                {isAuctionEnabled && (
                    <AppRoute
                        type="private"
                        exact
                        permission={PERMISSIONS.MENU_AUCTION_VIEW}
                        path={paths.auction}
                        component={LoadBoard}
                    />
                )}

                <AppRoute type="public" path={paths['not-found']} component={NotFound} />

                <Redirect to={`${paths['not-found']}`} />
            </Switch>
        </Suspense>
    );
};

export default Routing;
