import keys from 'lodash/keys';
import values from 'lodash/values';

import {TRUCK_PREFERRED_LOADS} from 'core/entities/Truck/constants/truckPreferredLoads';
import * as truckStatuses from 'core/entities/Truck/constants/truckStatuses';
import * as truckTypes from 'core/entities/Truck/constants/truckTypes';

import {isEuropeRegion, RegionValue} from 'utils/data/region';
import {changeDate, getCurrentDate} from 'utils/dateTime';
import {getTypeFieldNameFactory} from 'utils/typeScript';

import {FullAllianceSearchFormValues} from '../types/formTypes';

const getName = getTypeFieldNameFactory<FullAllianceSearchFormValues>();

export const createInitFormValues = (currentRegion: RegionValue) => {
    const {originalDate} = getCurrentDate();
    const isEurope = isEuropeRegion(currentRegion);

    const statusDateFrom = changeDate(originalDate, {operation: 'decrease', value: 1, valueType: 'days'})
        .dateInServerFormat;
    const statusDateTo = changeDate(originalDate, {operation: 'increase', value: 7, valueType: 'days'})
        .dateInServerFormat;

    return {
        [getName('status')]: truckStatuses.INITIAL_TRUCK_STATUSES_FOR_TRUCKS_NEARBY,
        [getName('type')]: values(truckTypes.GENERAL_TRUCK_TYPES).join(','),
        [getName('preferredLoads')]: keys(TRUCK_PREFERRED_LOADS).join(','),
        [getName('statusDateFrom')]: statusDateFrom,
        [getName('statusDateTo')]: statusDateTo,
        [getName('radius')]: 200,
        [getName('temperatureDegreesUnit')]: isEurope ? '°C' : '°F',
    };
};
